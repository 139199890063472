.tabClass {
  text-align: center;
  color: #ffffff;
  background-color: #232E55;
}

.tabClass.nav-item {
  margin-left: 1rem;
  margin-bottom: 1rem;
  border-bottom-left-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
}

.tabClass .nav-link {
  border-radius: 0rem !important;
}

.tabClass .nav-link.active {
  background-color: #fff !important;
  color: #232E55 !important;
}

.tabClass.active {
  background-color: #fff;
  color: #232E55;
}

textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active {
  outline: 0px !important;
  box-shadow: none !important;
}

*:focus {
  outline: 0px !important;
}


body,
html {
  height: 100%;
  overflow: hidden
}

.card,
.card-header {
  border-radius: 0px !important;
}

.second-device {
  background-color: black;
  color: white;

}


body {
  cursor: default;
}

body a {
  cursor: pointer;
}

* .btn:not(.disabled) {
  cursor: pointer;
}

.konvajs-content {
  border: 0px solid #fff !important
}

.currentPageHilighter .konvajs-content {
  border-color: orange !important;
}

.pageWrapperHeader {
  border-bottom: 0px solid #000;
}

.hilightPage {
  outline: none;
  border-color: orange;
  box-shadow: 0 0 10px orange;

}

.login-light form {

  border: 4px solid #32588C;

}