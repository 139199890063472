body {
  background-color: #f2f2f2;
}

@font-face {
  font-family: "Open Sans";
  src: local(OpenSans),
    url(assets/opensans/OpenSans.woff2) format("woff2"),
    url(assets/opensans/OpenSans.woff) format("woff"),
    url(assets/opensans/OpenSans.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}


@font-face {
  font-family: "Open Sans";
  src: local(OpenSans-SemiBold),
    url(assets/opensans/OpenSans-SemiBold.woff2) format("woff2"),
    url(assets/opensans/OpenSans-SemiBold.woff) format("woff"),
    url(assets/opensans/OpenSans-SemiBold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Open Sans";
  src: local(OpenSans-Bold),
    url(assets/opensans/OpenSans-Bold.woff2) format("woff2"),
    url(assets/opensans/OpenSans-Bold.woff) format("woff"),
    url(assets/opensans/OpenSans-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}


.HeadingHeadline-h1 {
  font-family: OpenSans;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
}

.HeadingHeadline-White-h1 {
  font-family: OpenSans;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--white);
}

.HeadingHeadline-h2 {
  font-family: "Open Sans";
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
}

.HeadingAvatar-Initials {
  font-family: OpenSans;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.4;
  letter-spacing: normal;
  text-align: center;
  color: var(--white);
}

.BodyText-bold-white-right {
  font-family: OpenSans;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: right;
  color: var(--white);
}

.BodyText-bold-centered {
  font-family: OpenSans;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
}

.BodyText-bold-white {
  font-family: OpenSans;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--white);
}

.BodyText-bold {
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

.BodyText-bold-right {
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: right;
}

.Buttons-CTAsCTA-white-right {
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1px;
  text-align: right;
}

.Buttons-CTAsCTA-centered {
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1px;
  text-align: center;
}

.Buttons-CTAsCTA-white-centered {
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1px;
  text-align: center;
  color: var(--white);
}

.Buttons-CTAsCTA-white {
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1px;
  color: var(--white);
}

.Buttons-CTAsCTA {
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1px;
}

.BodyText-white {
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

.BodyText {
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

.BodyText-centered {
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
}

.BodyText-right {
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: right;
}

.BodyCaption {
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
}

/** END FONT **/
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: transparent;
}

.nav-tabs .nav-link {
  border-radius: 0px !important;
}

.nav-tabs .nav-item {
  align-self: flex-end;
  margin-bottom: 0px;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-link {
  padding: 0.5rem 3rem;
}

.nav-tabs .nav-link.active {
  padding: 0.8rem 3rem;
}

.daySelection .activehack:not(.active),
.daySelection .nav-item:not(.active) {
  color: #000 !important;
  background-color: transparent !important;
}

.header .activehack:not(.active),
.header .nav-item:not(.active) {
  color: #000 !important;
  background-color: transparent !important;
}

* {
  font-family: "Open Sans", sans-serif;
}

i[class*="ri-"] {
  font-size: 24px !important;
  line-height: 24px;
}

.nav-item {
  text-transform: uppercase;
}

.alert-notice-header {
  border-radius: 0px;
  text-transform: uppercase;
}

.ql-container {
  height: 200px !important;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spin {
  position: absolute;
  width: 120px;
  height: 120px;
  margin: -60px 0 0 -60px;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}